import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-web';
import infoAnimationData1 from '../../animations/infoScr1.json';
import infoAnimationData2 from '../../animations/infoScr2.json';

export const IntroAnimation = ({updateLevel, isLoading, currentSession, currentLevel}) => {
    

    const audioBtn = new Audio("./sfx/nextLevel/Positive Game Win v2.mp3");
    const animContainerScr1 = React.createRef();
    const animContainerScr2 = React.createRef();
    const [currentScrState, setCurrentScr] = useState(1);
    let lottieAnim1;
    let lottieAnim2;

    const handleBtnClick = () => {
        document.getElementById('loader-init').style.display = "block";

        // if (currentSession.gameState !== null) {
        //     updateLevel(currentSession.gameState.level);
        // }
        if (currentScrState === 2)  updateLevel(2);
        setCurrentScr(currentScrState + 1);        
        audioBtn.play();
    }

    const handleBtnClickLeaderboard = () => {
        document.getElementById('loader-init').style.display = "block";

        // if (currentSession.gameState !== null) {
        //     updateLevel(currentSession.gameState.level);
        // }
        if (currentScrState === 2)  updateLevel(1);
        setCurrentScr(currentScrState + 1);        
        audioBtn.play();
    }

    useEffect(() => {
        const mainAnim = Lottie.loadAnimation({
            container: animContainerScr1.current,
            animationData: infoAnimationData1,
            autoplay: false,
            loop: false
          }); 

          lottieAnim1 = mainAnim;
          lottieAnim1.stop();
          lottieAnim1.addEventListener('loaded_images', function() { lottieAnim1.play(); document.getElementById('loader-init').style.display = "none";});
    
          const mainAnim2 = Lottie.loadAnimation({
            container: animContainerScr2.current,
            animationData: infoAnimationData2,
            autoplay: false,
            loop: false
        }); 

        lottieAnim2 = mainAnim2;
        lottieAnim2.stop();
        lottieAnim2.addEventListener('loaded_images', function() { lottieAnim2.play(); document.getElementById('loader-init').style.display = "none";});

        if (currentScrState === 2) {
            //setIntroAniamtion(infoAnimationData2);
                      
            mainAnim2.play();
            
            } 
    }, [currentScrState]);
     
    useEffect(() => {
        isLoading(false);
    }, []);

    return (
        <div className="intro-scr-animation-container">
            {currentScrState === 1 && <div className='c-container' ref={animContainerScr1}></div> }
            {currentScrState === 2 && <div className='c-container' ref={animContainerScr2}></div> }
            
            {currentScrState === 1 && <div className='intro continue_btn opacity-0'>
                <img src='./images/btns/NASTAVI.png'  onClick={handleBtnClick} />
            </div> }

            {currentScrState === 2 && <div className='intro intro_btn opacity-0'>
                <div className='flex opacity-0'>
                    <img src='./images/btns/NASTAVI.png'  onClick={handleBtnClickLeaderboard} />
                    <img src='./images/btns/NASTAVI.png'  onClick={handleBtnClick} />
                </div>
            </div> }
            
        </div>
    )
}
