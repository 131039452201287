import React, { useState, useEffect, memo } from "react";
// import axios from "axios";
import Lottie from 'lottie-web';
import endAnimationData from '../animations/leaderboardScr.json';
import { useRecoilValue } from 'recoil';
import { scoreState } from '../game/atom';
import getAxiosInstance from "../config/http";

const LeaderboardDesk = memo(({ updateLevel, currentSession, currentLevel, handleNextLevel, isLoading }) => {
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [sessionUserdData, setSessionUserdData] = useState([]);
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedPageNumberGroup, setSelectedPageNumberGroup] = useState(0);
    const [indexOfLastItem, setIndexOfLastItem] = useState(0);
    const [indexOfFirstItem, setIndexOfFirstItem] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const animationContainer = React.createRef();
    const savedScore = useRecoilValue(scoreState);
    const [startBtnVisibility, setStartBtnVisibility] = useState('hide');
    const audioBtn = new Audio("./sfx/nextLevel/Positive Game Win v2.mp3");
    const audioWelcome = new Audio("./sfx/welcome/Slot Game Win v2.mp3")
    let lottieAnim;
    const http = getAxiosInstance();

    const handleBtnClickStart = () => {
        document.getElementById('loader-init').style.display = "block";
        updateLevel(2);
        audioBtn.play();
    }

    const handleBtnClickBack = () => {
        document.getElementById('loader-init').style.display = "block";
        updateLevel(0);
        audioBtn.play();
    }

    React.useEffect(() => {
        const anim = Lottie.loadAnimation({
            container: animationContainer.current,
            animationData: endAnimationData,
            autoplay: false,
            loop: false
        });
        lottieAnim = anim;
        lottieAnim.stop();
        lottieAnim.addEventListener('loaded_images', function () { lottieAnim.play(); setStartBtnVisibility('show'); document.getElementById('loader-init').style.display = "none"; });
        const timer = setTimeout(() => {
            // console.log('This will run after 1 second!');
            // setStartBtnVisibility('show');
            audioWelcome.play();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }

    }, [currentSession]);

    React.useEffect(() => {
        isLoading(false);
    });

    useEffect(() => {
        setLeaderboardData([]); // empty the leaderboardData state

        const fetchData = async () => {
            let response;
            response = await http.get(
                `/game-session-leaderboard`
            );

        setLeaderboardData(response.data);
        setTotalPages(Math.ceil(response.data.length / 100));
        };

        fetchData();
    }, [selectedDay]);

    useEffect(() => {

        const fetchUserData = async () => {
            let responseData;
            responseData = await http.get(
                `/game-session-user/${currentSession.id}`
            );

            setSessionUserdData(responseData.data);
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const itemsPerPage = 8;
        setIndexOfLastItem(selectedPageNumberGroup * itemsPerPage);
        setIndexOfFirstItem(indexOfLastItem - itemsPerPage);
    }, [selectedPageNumberGroup]);

    const handleNextClick = () => {
        setSelectedDay(new Date(selectedDay.getTime() + 24 * 60 * 60 * 1000));
        setCurrentPage(1);
        setSelectedPageNumberGroup(0);
    };

    const handlePrevClick = () => {
        setSelectedDay(new Date(selectedDay.getTime() - 24 * 60 * 60 * 1000));
        setCurrentPage(1);
        setSelectedPageNumberGroup(0);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const itemsPerPage = 8;
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setCurrentItems(leaderboardData.slice(startIndex, endIndex));
    }, [leaderboardData, currentPage]);

    const pageNumbers = Array(totalPages).fill().map((_, i) => i + 1);

    const pageNumbersGroup = [];
    for (let i = 1; i <= totalPages; i += 100) {
        pageNumbersGroup.push(`${i}-${Math.min(i + 99, totalPages)}`);
    }


    return (
        <div>
            <div className='c-container' ref={animationContainer}>
                <div className="leaderboard">
                    <div className="leaderboard__text board">
                        {/* Čestitamo, osvojio/la si {sessionUserdData.user500Points} poena! Tvoja trenutna pozicija je #{sessionUserdData.userRank} */}
                        Trenutno je potrebno da osvojiš najmanje {sessionUserdData.user500Points} poena kako bi ušao/la u prvih 500, ali imaj u vidu da do kraja takmičarskog dana neko ipak može da te prestigne.
                    </div>
                    {/* <h2 className="leaderboard__title">&nbsp;</h2> */}
                    <table className="leaderboard__table">
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr
                                    key={item.uid}
                                    className={
                                        item.uid === sessionUserdData.userUid
                                            ? "leaderboard__row leaderboard__row--highlight"
                                            : "leaderboard__row"
                                    }
                                >
                                    <td>{item.rankingPosition.toString().padStart(3, '0')}</td>
                                    <td>Korisnik {item.uid}</td>
                                    <td>{new Date(item.datePlayed).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false })}h</td>
                                    <td>{item.points} poena</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <hr />
                    <table className="leaderboard__table">
                        <tbody>
                                <tr
                                    key="leaderboard_500"
                                    className={
                                        sessionUserdData.user500Uid === sessionUserdData.userUid
                                            ? "leaderboard__row leaderboard__row--highlight"
                                            : "leaderboard__row"
                                    }
                                >
                                    <td>{sessionUserdData.user500Rank}</td>
                                    <td>Korisnik {sessionUserdData.user500Uid}</td>
                                    <td>{new Date(sessionUserdData.user500Date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false })}h</td>
                                    <td>{sessionUserdData.user500Points} poena</td>
                                </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='intro intro_btn opacity-0'>
                <div className='flex opacity-0'>
                    <img src='./images/btns/NASTAVI.png'  onClick={handleBtnClickBack} />
                    <img src='./images/btns/NASTAVI.png'  onClick={handleBtnClickStart} />
                </div>
            </div>
        </div>
    );
});
export default LeaderboardDesk;