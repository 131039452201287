import React from 'react';
import { isMobile } from 'react-device-detect';

const Dot = (props) => {
    const {color, x, y, size, index, score, symbol, onClick} = props;  
    const posNeg = symbol < 14 ? 1 : -1;
    const dotStyle = {
        // background: symbol,
        // backgroundColor: color,
        background: isMobile ? `url(/images/mobile/symbols/${symbol}.png)` : `url(/images/symbols/${symbol}.png)`,
        height: `${size}px`,
        width: `${size}px`,
        transform: (x > 100) ? `rotate(${posNeg*(y*5/x)*10}deg)` : `rotate(${posNeg*(y*5/(x+50))*3.14}deg)`,
        left: `${x}px`,
        top: `${y}px`,
    };

    return (
        <div 
            key={symbol*x*y}
            className="dot"
            style={dotStyle}
            score={score}
            // onClick={() => onClick(index)}
        />
    );
};

export default Dot;
